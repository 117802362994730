<template>
  <b-container fluid>
    <b-modal id="modal-competencia" class="modal-sq" size="lg" hide-footer>
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between" style="width: 100%">
          <p class="mb-0 h4">
            <strong>{{ empresa.nombre == "Grupo GSE" ? "habilidad" : "competencia" }} información</strong>
          </p>
          <b-button @click="close()" class="btn btn-pm" variant="danger">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <template #default>
        <p><strong>Tipo: </strong>{{ modalCompetencia.tipoCompetencia.descripcion }}</p>
        <p><strong>Titulo: </strong>{{ modalCompetencia.titulo }}</p>
        <p><strong>Descripción: </strong>{{ modalCompetencia.descripcion }}</p>
      </template>
    </b-modal>
    <b-modal id="modal-administrador-funciones" class="modal-sq" size="lg" hide-footer scrollable>
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between" style="width: 100%">
          <p class="mb-0 h4"><strong>Administrador de funciones</strong></p>
          <b-button @click="close()" class="btn btn-pm" variant="danger">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <template #default>
        <b-row class="my-2">
          <b-col>
            <label>Agregar función</label>
            <b-input-group>
              <b-form-input @keydown.enter="agregarFuncion()" v-model="nuevaFuncion" :disabled="isLoadingFunction"></b-form-input>
              <b-input-group-append>
                <b-utton style="min-width: 80px" variant="info" @click.prevent="agregarFuncion()" :disabled="isLoadingFunction">
                  <i v-if="isLoadingFunction" class="fas fa-spinner fa-spin"></i>
                  <span v-else>Agregar</span>
                </b-utton>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-list-group>
              <p>Lista de funciones</p>
              <div v-if="funcionesDelCargo.length == 0">
                <b-list-group-item>
                  <p class="mb-0">No existen funciones</p>
                </b-list-group-item>
              </div>
              <div v-else>
                <b-list-group-item v-for="(item, idx) in funcionesDelCargo" :key="idx">
                  <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ item.nombre }}</p>
                    <b-button @click.prevent="deleteFuncion(idx)" class="btn btn-pm" variant="transparent">
                      <i class="fas fa-solid fa-trash px-1 text-danger"></i>
                    </b-button>
                  </div>
                </b-list-group-item>
              </div>
            </b-list-group>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <section>
      <b-row>
        <b-col sm="12">
          <card class="pb-2">
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Nuevo cargo</b></h4>
            </template>
            <h4 class="px-4 py-4"><b>Datos de identificación</b></h4>
            <form @submit.prevent="crearCargo">
              <div class="row px-5">
                <b-row>
                  <div class="col-md-6">
                    <div>
                      <label class="form-label">Nombre Cargo</label>
                      <input required v-model="cargoBase.nombre" :disabled="id != null" placeholder="" type="text" class="form-control" id=" " />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <label class="form-label">Nivel Cargo</label>
                      <b-form-select required v-model="cargoBase.tipoCargoId" text-field="descripcion" value-field="id" :options="ListTipoCargo" :disabled="id != null">
                        <template #first>
                          <b-form-select-option selected :value="null" disabled> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Sucursal</label>
                      <b-form-select
                        required
                        v-model="cargoBase.sucursalId"
                        text-field="descripcion"
                        value-field="id"
                        :options="ListSucursales"
                        :disabled="id != null"
                        @change.native="getDepartamentosBySucursalId(cargoBase.sucursalId)"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Departamento</label>
                      <b-form-select
                        required
                        v-model="cargoBase.departamentoId"
                        text-field="descripcion"
                        value-field="id"
                        :options="ListDepartamento"
                        :disabled="id != null"
                        @change.native="getAreasByDepartamentoId(cargoBase.departamentoId)"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled v-if="cargoBase.sucursalId"> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Area</label>
                      <b-form-select required v-model="cargoBase.areaId" text-field="descripcion" value-field="id" :options="listArea" :disabled="id != null">
                        <template #first>
                          <b-form-select-option selected :value="null" disabled v-if="cargoBase.departamentoId"> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Cargo superior</label>
                      <b-form-select v-model="cargoBase.cargoDependienciaId" text-field="nombre" :disabled="id != null" value-field="id" :options="ListCargo">
                        <template #first>
                          <b-form-select-option selected :value="null" disabled> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                </b-row>
              </div>
              <div class="d-flex justify-content-end pr-2">
                <div class="pr-4" v-if="!id">
                  <!-- Solo debe mostrarse si NO existe el cargo -->
                  <vs-button primary   icon animation-type="vertical" class="" :disabled="getLoading">
                    <i class="fas fa-folder-plus pr-1"></i>

                    <i v-if="getLoading" class="fas fa-spinner fa-spin"></i>
                    <span v-else> Crear cargo </span>
                    <template #animate>
                      <i class="fas fa-folder-plus"></i>
                    </template>
                  </vs-button>
                </div>
              </div>
            </form>
          </card>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
const Swal = require("sweetalert2");
import { mapGetters } from "vuex";

export default {
  name: "DataTable",
  data() {
    return {
      ListSucursales: [],
      ListDepartamento: [],
      listArea: [],

      ListCargo: [],
      ListTipoCargo: [],

      listaCompetencias: [],
      filtradoTemporal: [],
      selectedCompetencias: [],

      nivelesDeCompetencia: [],
      funcionesDelCargo: [],
      nuevaFuncion: "",

      inputFilterCoincidente: "",
      inputFilterTipo: null,

      modalCompetencia: {},

      // Pruebas
      id: null,
      isLoadingFunction: false,

      cargoBase: {
        sucursalId: null,
        departamentoId: null,
        areaId: null,
        nombre: null,
        tipoCargoId: null,
        cargoDependienciaId: null,
      },
      empresa: {},
    };
  },
  mounted() {
    // Traer en el siguienteorden.  Sucursal -> Departamento -> Area -> Cargo
    let { empresa } = this.$store.getters.userLoggedIn;
    this.empresa = empresa;

    this.getCompetencias();
    this.getSucursalesByEmpresaId();
    this.getNivelesDeCargo();
    this.getListaCargo(empresa.id);

    this.$store.getters
      .fetchGet({ path: "CargoCompetencia/GetAllCargoCompetenciaNivel" })
      .then((response) => {
        if (!response.ok) {
          return response.text();
        }
        return response.json();
      })
      .then((result) => {
        if (typeof result == "object") {
          this.nivelesDeCompetencia = result.map((item) => {
            return {
              value: item.id,
              text: item.value,
            };
          });
        }
      });
  },
  computed: {
    ...mapGetters(["getLoading"]),
    bancoDeCompetenciasFiltrado() {
      return this.listaCompetencias.filter((item) => {
        const tipoMatch = !this.inputFilterTipo || item.tipoCompetencia.descripcion === this.inputFilterTipo;
        const coincidenteMatch = !this.inputFilterCoincidente || (item.titulo && item.titulo.toLowerCase().includes(this.inputFilterCoincidente.toLowerCase()));
        return tipoMatch && coincidenteMatch;
      });
    },
    tiposDeCompetencia() {
      //Crea un conjunto de tipos de competencia
      let tipos = new Set();
      this.listaCompetencias.forEach((item) => tipos.add(item.tipoCompetencia.descripcion));
      return [{ value: null, text: "Por tipo" }, ...tipos];
    },
  },
  methods: {
    isCompetenciaSelected(competencia) {
      return this.selectedCompetencias.find((item) => item.id == competencia.id);
    },
    agregarCompetencia(element) {
      if (this.id == null) {
        Swal.fire({
          title: "Error!",
          text: "Debe crear el cargo primero.",
          icon: "error",
          confirmButtonText: "Continuar",
        });
      } else {
        this.selectedCompetencias.push(element);
      }
    },
    eliminarDeLosSeleccionados(element) {
      this.selectedCompetencias = this.selectedCompetencias.filter((item) => item.id != element.id);
    },
    getListaCargo(empresaId) {
      this.$store.getters
        .fetchGet({ path: `Cargo/ListCargo/${empresaId}` })
        .then((res) => res.json())
        .then((res) => (this.ListCargo = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    getNivelesDeCargo() {
      this.$store.getters
        .fetchGet({ path: `TipoCargo/TiposDeCargo` })
        .then((res) => res.json())
        .then((res) => (this.ListTipoCargo = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    getAreasByDepartamentoId(id) {
      console.log(id);
      this.$store.getters
        .fetchGet({ path: `Area/ListAreaDepartamento/${id}` })
        .then((res) => res.json())
        .then((res) => (this.listArea = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    getDepartamentosBySucursalId(sucursalId) {
      console.log(sucursalId);

      this.$store.getters
        .fetchGet({ path: `Departamento/ListDepartamentoSucursal/${sucursalId}` })
        .then((res) => res.json())
        .then((res) => (this.ListDepartamento = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    getSucursalesByEmpresaId() {
      let { empresa } = this.$store.getters.userLoggedIn;

      this.$store.getters
        .fetchGet({ path: `Sucursal/ListSucursalEmpresa/${empresa.id}` })
        .then((res) => res.json())
        .then((res) => (this.ListSucursales = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    showModalCompetencia(data) {
      this.modalCompetencia = data;
      this.$bvModal.show("modal-competencia");
    },
    showModalAdministradorDeFunciones() {
      this.$bvModal.show("modal-administrador-funciones");
    },
    agregarFuncion() {
      if (this.nuevaFuncion == "") return;
      let data = {
        CargoId: this.id,
        Nombre: this.nuevaFuncion,
      };
      this.isLoadingFunction = true;

      this.$store.getters
        .fetchPost({ path: "CargoCompetencia/CrearFuncion", data })
        .then((response) => response.json())
        .then((result) => {
          this.funcionesDelCargo.push(result);
          this.nuevaFuncion = "";
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          this.isLoadingFunction = false;
        });
    },
    deleteFuncion(idx) {
      this.$store.getters
        .fetchDelete({ path: "CargoCompetencia/EliminarFuncion/" + this.funcionesDelCargo[idx].id })
        .then((response) => response.json())
        .then((result) => {
          console.log("Resultado:", result);
          this.funcionesDelCargo.splice(idx, 1);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    getCompetencias() {
      this.$store.getters
        .fetchGet({ path: `Competencia/Competencias` })
        .then((res) => res.json())
        .then((res) => {
          console.log("competencias ", res);
          this.listaCompetencias = res;
        })
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    crearCargo() {
      this.$store.commit("setLoading", true);

      this.$store.getters
        .fetchPost({ path: "Cargo/NuevoCargo", data: this.cargoBase })
        .then((res) => res.json())
        .then((res) => {
          this.id = res.id;

          // Dos opciones de swal crear nuevo cargo o ir a la lista de cargos

          Swal.fire({
            title: "Exito!",
            text: "Se ha creado el cargo correctamente.",
            icon: "success",
            confirmButtonText: "Nuevo cargo",
            showCancelButton: true,
            cancelButtonText: "Agregar",
            // cancelButtonColor: "#dc3545",
            // confirmButtonColor: "#28a745",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.go();
            } else {
              this.$router.push({ name: "Cargos_editar", params: { id: this.id } });
            }
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: "No se ha podido crear el cargo.",
            icon: "error",
            confirmButtonText: "Continuar",
          });
          console.log("Revisar conexión: ", err);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },

    saveCompetencias() {
      console.log(this.id);
      let competencias;

      try {
        competencias = this.selectedCompetencias.map((element) => {
          //Levantar un error si element de tipo funcion responsabilidad no contiene algún valor
          if (element.tipoCompetencia.descripcion == "Responsabilidad" && !("funcion" in element)) throw new Error("Agregar función a \n" + element.titulo);

          //Levanta un error si no se ha asignado un nivel de competencia
          if (!("nivel" in element)) throw new Error("Agregar nivel a \n" + element.titulo);

          return {
            CompetenciaId: element.id,
            CargoId: this.id,
            CargoCompetenciaNivelId: element.nivel,
            CargoFuncionesId: element.funcion ? element.funcion : null,
          };
        });
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Continuar",
        });
        return;
      }

      this.$store.commit("setLoading", true);

      this.$store.getters
        .fetchPost({ path: "CargoCompetencia/PostRelacionCargoCompetenciaVarios", data: competencias })
        .then((response) => {
          if (!response.ok) {
            return response.text();
          }
          return response.json();
        })
        .then((result) => {
          if (typeof result == "object") {
            //Crea dos botones para actualizar la pagina y para regresar a la pagina anterior
            Swal.fire({
              title: "Exito!",
              text: "Se ha guardado correctamente.",
              icon: "success",
              confirmButtonText: "Nuevo cargo",
              showCancelButton: true,
              cancelButtonText: "Lista de cargos",
              cancelButtonColor: "#dc3545",
              confirmButtonColor: "#28a745",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.go();
              } else {
                this.$router.go(-1);
              }
            });
          } else {
            console.log("Error:", result);
            Swal.fire({
              title: "Error!",
              text: "No se ha podido crear el cargo.",
              icon: "error",
              confirmButtonText: "Continuar",
            });
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style scoped>
.btn {
  padding: 2px 5px;
  margin: 0px 2px;
  border: none;
  border-radius: 5px;
}

.btn-pm {
  position: relative;
  min-width: 20px;
  height: 20px;
  text-align: center;
}

.btn-pm i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sm-input {
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 11px;
  line-height: 1.5;
}

.limit-scroll-height {
  position: relative;
  height: 80vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  border-radius: 11px;
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.limit-scroll-height::-webkit-scrollbar {
  width: 0;
}

.limit-scroll-height > * {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.limit-scroll-height > *:first-child {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(28, 47, 78);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
